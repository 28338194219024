import { notification, Select } from "antd";
import React from "react";

import Notifications from "../../assets/data/notifications";
import {
  useArchiveWorkspaceLocale,
  useCreateWorkspaceLocale,
  useLocales,
  useUnarchiveWorkspaceLocale,
  useWorkspaceLocales,
  useWorkspaceSettings,
} from "../../client";
import { useLocaleContext } from "../LocaleContext";
import { getSelectedWorkspaceLocales } from "./WorkspaceLocaleSelect.utils";

const WorkspaceLocaleSelect: React.FunctionComponent = () => {
  const { resetLocale } = useLocaleContext();
  const workspaceSettings = useWorkspaceSettings({
    select: (data) => data.data,
  });
  const workspaceLocales = useWorkspaceLocales({
    select: (data) => data.data,
  });
  const locales = useLocales({
    select: (data) => data.data,
  });

  const createWorkspaceLocale = useCreateWorkspaceLocale({
    onSuccess: () => {
      notification.success({
        message: "Success",
        description: Notifications.WorkspaceLocaleCreate.success,
      });
    },
    onError: (error) => {
      notification.error({
        message: "Error",
        description: `${Notifications.WorkspaceLocaleCreate.error} ${error?.errors?.[0]?.detail}`,
      });
    },
  });
  const archiveWorkspaceLocale = useArchiveWorkspaceLocale({
    onSuccess: () => {
      resetLocale();
      notification.success({
        message: "Success",
        description: Notifications.WorkspaceLocaleArchive.success,
      });
    },
    onError: (error) => {
      notification.error({
        message: "Error",
        description: `${Notifications.WorkspaceLocaleArchive.error} ${error?.errors?.[0]?.detail}`,
      });
    },
  });
  const unarchiveWorkspaceLocale = useUnarchiveWorkspaceLocale({
    onSuccess: () => {
      notification.success({
        message: "Success",
        description: Notifications.WorkspaceLocaleUnarchive.success,
      });
    },
    onError: (error) => {
      notification.error({
        message: "Error",
        description: `${Notifications.WorkspaceLocaleUnarchive.error} ${error?.errors?.[0]?.detail}`,
      });
    },
  });

  const handleSelectLocale = React.useCallback(
    (localeIdString) => {
      if (!workspaceLocales.data) {
        return;
      }

      const localeId = parseInt(localeIdString);
      const workspaceLocale = workspaceLocales.data.find(
        (workspaceLocale) => workspaceLocale.locale_id === localeId
      );

      if (!workspaceLocale) {
        createWorkspaceLocale.mutate({ locale_id: localeId });
      } else if (workspaceLocale.state === "archived") {
        unarchiveWorkspaceLocale.mutate({ id: workspaceLocale.id });
      } else {
        archiveWorkspaceLocale.mutate({ id: workspaceLocale.id });
      }
    },
    [
      archiveWorkspaceLocale,
      createWorkspaceLocale,
      unarchiveWorkspaceLocale,
      workspaceLocales.data,
    ]
  );

  const handleDeselectLocale = React.useCallback(
    (localeIdString) => {
      const localeId = parseInt(localeIdString);
      const workspaceLocale = workspaceLocales?.data?.find(
        (workspaceLocale) => workspaceLocale.locale_id === localeId
      );
      if (
        workspaceLocale &&
        workspaceSettings.data?.default_workspace_locale?.data.locale_id ===
          localeId
      ) {
        notification.error({
          message: "Error",
          description: Notifications.WorkspaceLocaleArchiveConflict.error,
        });
      } else if (workspaceLocale) {
        archiveWorkspaceLocale.mutate({ id: workspaceLocale.id });
      }
    },
    [archiveWorkspaceLocale, workspaceLocales?.data, workspaceSettings?.data]
  );

  return (
    <Select
      loading={
        locales.isFetching ||
        workspaceSettings.isFetching ||
        workspaceLocales.isFetching ||
        createWorkspaceLocale.isLoading ||
        archiveWorkspaceLocale.isLoading ||
        unarchiveWorkspaceLocale.isLoading
      }
      mode="multiple"
      onDeselect={handleDeselectLocale}
      onSelect={handleSelectLocale}
      style={{ width: "300px" }}
      value={
        workspaceLocales?.data
          ? getSelectedWorkspaceLocales(workspaceLocales.data)
          : []
      }
    >
      {locales?.data?.map((locale) => (
        <Select.Option key={locale.id} value={locale.id}>
          {locale.display_name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default WorkspaceLocaleSelect;
