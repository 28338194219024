const iconLinkOut = `
    <div class="tab-icon">
      <svg
        width="18"
        aria-label="Opens in a new tab"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.29443 2.53125V15.4688C2.29443 15.7799 2.5458 16.0312 2.85693 16.0312H15.7944C16.1056 16.0312 16.3569 15.7799 16.3569 15.4688V9.14062C16.3569 9.06328 16.2937 9 16.2163 9H15.2319C15.1546 9 15.0913 9.06328 15.0913 9.14062V14.7656H3.56006V3.23438H9.18506C9.2624 3.23438 9.32568 3.17109 9.32568 3.09375V2.10938C9.32568 2.03203 9.2624 1.96875 9.18506 1.96875H2.85693C2.5458 1.96875 2.29443 2.22012 2.29443 2.53125Z"
            fill="#5E5E5E"
          />
          <path
            d="M14.8253 4.44957L15.7429 5.36715C15.7616 5.38574 15.7851 5.39871 15.8108 5.40459C15.8365 5.41047 15.8633 5.40903 15.8882 5.40042C15.9131 5.39181 15.9351 5.37639 15.9517 5.3559C15.9683 5.33542 15.9788 5.31069 15.982 5.28453L16.3511 2.13102C16.3617 2.04137 16.2861 1.96403 16.1947 1.97457L13.0411 2.34371C12.9251 2.35778 12.8759 2.50016 12.9585 2.58278L13.8796 3.50387L9.37611 8.00738C9.32162 8.06188 9.32162 8.15153 9.37611 8.20602L10.1214 8.95133C10.1759 9.00582 10.2656 9.00582 10.3201 8.95133L14.8253 4.44957Z"
            fill="#5E5E5E"
          />
      </svg>
    </div>
  `;

export const defaultHtmlFooter = `
  <div>
    <style>
      footer {
        font-family: "Public Sans", sans-serif;
        padding: 24px 20px 78px;
        background-color: #f9fafb;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 12px;
      }
      footer a {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #162d6d;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-decoration: underline;
      }
      footer ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
      footer p {
        margin: 0;
      }
      footer .links-wrapper {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
      footer .copyright {
        color: #1a1a1a;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
      footer .tab-icon {
        padding-top: 4px;
      }

      @media (min-width: 768px) {
        footer {
          flex-direction: row;
          padding: 24px 120px;
          justify-content: space-between;
          align-items: center;
        }

        footer .links-wrapper {
          flex-direction: row;
          align-items: flex-start;
          gap: 24px;
        }
      }
    </style>
    <footer>
      <img
        src='https://cdn.prod.website-files.com/61faadbd3ec7085e2c97a896/623c8ec331d301f00d735333_logo-evidation-black.svg'
        alt='Evidation logo'
        width='122'
      />
      <ul class='links-wrapper'>
        <li>
          <a href='https://evidation.com/contact' target='_blank'>
            Contact Us
            ${iconLinkOut}
          </a>
        </li>
        <li>
          <a href='https://evidation.com/terms' target='_blank'>
            Terms & Conditions
            ${iconLinkOut}
          </a>
        </li>
        <li>
          <a href='https://evidation.com/privacy' target='_blank'>
            Privacy Policy
            ${iconLinkOut}
          </a>
        </li>
      </ul>
      <p class='copyright'>Evidation @ 2024</p>
    </footer>
  </div>
`;
