import { evidationTheme, NativeBaseProvider } from "@evidation-shared/eve";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Layout } from "antd";
import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Route, Switch, useHistory } from "react-router-dom";

import { ClientProvider, PortalClientError } from "./client";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import AuthorizedRoute from "./components/Authorized/AuthorizedRoute";
import CognitoConfigManager from "./components/CognitoConfigManager";
import FaviconLoader from "./components/FaviconLoader";
import FeatureToggleDropdown from "./components/FeatureToggleDropdown";
import Head from "./components/Head";
import { LocaleProvider } from "./components/LocaleContext";
import SessionManager from "./components/SessionManager";
import SideNavigationMenu from "./components/SideNavigationMenu";
import roles from "./constants/roles";
import CustomizationView from "./views/CustomizationView";
import DeepLinkListView from "./views/DeepLinkListView";
import EditDeepLinkView from "./views/EditDeepLinkView";
import EditRecurringView from "./views/EditRecurringView";
import ProgramListView from "./views/ProgramListView";
import WorkspaceSettings from "./views/WorkspaceSettingsView";

// const AddContentView = React.lazy(() => import("./views/AddContentView"));
const AdminListView = React.lazy(() => import("./views/AdminListView"));
const ApplicationsListView = React.lazy(
  () => import("./views/ApplicationsListView")
);
// const ContentListView = React.lazy(() => import("./views/ContentListView"));
const EditAdminView = React.lazy(() => import("./views/EditAdminView"));
const EditApplicationView = React.lazy(
  () => import("./views/EditApplicationView")
);
// const EditContentView = React.lazy(() => import("./views/EditContentView"));
const EditExperimentView = React.lazy(
  () => import("./views/EditExperimentView")
);
const EditOfferView = React.lazy(() => import("./views/EditOfferView"));
const EditProgramView = React.lazy(() => import("./views/EditProgramView"));
const EditUserView = React.lazy(
  () => import("./views/EditUserView/EditUserView")
);
const ExperimentListView = React.lazy(
  () => import("./views/ExperimentListView")
);
const HomeView = React.lazy(() => import("./views/HomeView"));
const ImageListView = React.lazy(() => import("./views/ImageListView"));
const InsightTypeListView = React.lazy(
  () => import("./views/InsightTypeListView")
);
const LoginView = React.lazy(() => import("./views/LoginView"));
const LogoutView = React.lazy(() => import("./views/LogoutView"));
const OfferListView = React.lazy(() => import("./views/OfferListView"));
const RecurringListView = React.lazy(() => import("./views/RecurringListView"));
const UserListView = React.lazy(() => import("./views/UserListView"));
/**
 * Consents
 * */
const ConsentsListView = React.lazy(() => import("./views/ConsentsListView"));
const AddConsentView = React.lazy(() => import("./views/AddConsentView"));
const EditConsentView = React.lazy(() => import("./views/EditConsentView"));
/**
 * Articles
 * */
const ArticleListView = React.lazy(() => import("./views/ArticleListView"));
const AddArticleView = React.lazy(() => import("./views/AddArticleView"));
const EditArticleView = React.lazy(() => import("./views/EditArticleView"));

/**
 * Communication
 * */
const CommunicationsListView = React.lazy(
  () => import("./views/CommunicationsListView")
);
const AddCommunicationView = React.lazy(
  () => import("./views/AddCommunicationView")
);
const EditCommunicationView = React.lazy(
  () => import("./views/EditCommunicationView")
);

/**
 * App Settings
 * */
const AppSettingsView = React.lazy(() => import("./views/AppSettingsView"));

const CLIENT_PROVIDER_ENVIRONMENT =
  window.env.REACT_APP_PORTAL_ENVIRONMENT ?? "staging";

// MAYBE? https://blog.logrocket.com/building-a-modal-module-for-react-with-react-router/
const App = () => {
  const history = useHistory();
  const portalAdminClient = React.useMemo(() => new QueryClient(), []);

  const accessTokenRef = React.useRef("");

  const getAccessToken = React.useCallback(
    () => accessTokenRef.current,
    [accessTokenRef]
  );

  const handleError = (error: unknown) => {
    const portalError = error as PortalClientError;
    if (portalError.status === 401) {
      history.push("/logout");
    }
  };

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <NativeBaseProvider theme={evidationTheme}>
        <QueryClientProvider client={portalAdminClient}>
          <ClientProvider
            environment={CLIENT_PROVIDER_ENVIRONMENT}
            getAccessToken={getAccessToken}
            queryClientOptions={{
              queries: {
                onError: handleError,
              },
              mutations: {
                onError: handleError,
              },
            }}
          >
            <FaviconLoader />
            <CognitoConfigManager>
              <SessionManager
                onChange={(session) => {
                  if (session.accessToken) {
                    accessTokenRef.current = session.accessToken;
                  }
                }}
              >
                <HelmetProvider>
                  <Head />
                  <Switch>
                    <Route component={LogoutView} exact path="/logout" />
                    <Route component={LoginView} exact path="/login" />
                    <AuthenticatedRoute path="/">
                      <LocaleProvider>
                        <Layout>
                          <Layout.Sider
                            breakpoint="lg"
                            style={{
                              overflow: "auto",
                              height: "100vh",
                              position: "fixed",
                              left: 0,
                            }}
                          >
                            <SideNavigationMenu></SideNavigationMenu>

                            {(window.env.REACT_APP_PORTAL_ENVIRONMENT ===
                              "local" ||
                              window.env.REACT_APP_PORTAL_ENVIRONMENT ===
                                "staging") && <FeatureToggleDropdown />}
                          </Layout.Sider>
                          <Layout.Content
                            className="site-layout"
                            style={{ marginLeft: 200, minHeight: "100vh" }}
                          >
                            <Switch>
                              <Route component={HomeView} exact path="/" />
                              <AuthorizedRoute
                                component={AdminListView}
                                exact
                                path={["/admins", "/admins/add"]}
                                roles={[roles.adminsAdmin]}
                              />
                              <AuthorizedRoute
                                component={EditAdminView}
                                path="/admins/:id"
                                roles={[roles.adminsAdmin]}
                              />
                              <AuthorizedRoute
                                component={ApplicationsListView}
                                exact
                                path={["/applications", "/applications/add"]}
                                roles={[roles.applicationAdmin]}
                              />
                              <AuthorizedRoute
                                component={EditApplicationView}
                                path="/applications/:id"
                                roles={[roles.applicationAdmin]}
                              />
                              <AuthorizedRoute
                                component={ArticleListView}
                                exact
                                path={["/articles"]}
                                roles={[roles.superAdmin]}
                              />
                              <AuthorizedRoute
                                component={AddArticleView}
                                path="/articles/add"
                                roles={[roles.superAdmin]}
                              />
                              <AuthorizedRoute
                                component={EditArticleView}
                                path="/articles/:id"
                                roles={[roles.superAdmin]}
                              />
                              {/* TODO: Add dedicated role for eConsents, content, emails for routes and sidebar links */}
                              <AuthorizedRoute
                                component={ConsentsListView}
                                exact
                                path="/consents"
                                roles={[roles.applicationAdmin]}
                              />
                              <AuthorizedRoute
                                component={AddConsentView}
                                path="/consents/add"
                                roles={[roles.applicationAdmin]}
                              />
                              <AuthorizedRoute
                                component={EditConsentView}
                                exact
                                path="/consents/:id"
                                roles={[roles.applicationAdmin]}
                              />
                              <AuthorizedRoute
                                component={CommunicationsListView}
                                exact
                                path="/communications"
                                roles={[roles.applicationAdmin]}
                              />
                              <AuthorizedRoute
                                component={AddCommunicationView}
                                path="/communications/add"
                                roles={[roles.applicationAdmin]}
                              />
                              <AuthorizedRoute
                                component={EditCommunicationView}
                                exact
                                path="/communications/:id"
                                roles={[roles.applicationAdmin]}
                              />
                              <AuthorizedRoute
                                component={ExperimentListView}
                                exact
                                path={["/experiments", "/experiments/add"]}
                                roles={[roles.experimentConfigAdmin]}
                              />
                              <AuthorizedRoute
                                component={EditExperimentView}
                                path="/experiments/:id"
                                roles={[roles.experimentConfigAdmin]}
                              />
                              <AuthorizedRoute
                                component={ImageListView}
                                path="/images"
                                roles={[roles.imageManager]}
                              />
                              <AuthorizedRoute
                                component={InsightTypeListView}
                                exact
                                path={["/insight_types", "/insight_types/add"]}
                                roles={[roles.insightAdmin]}
                              />
                              <AuthorizedRoute
                                component={OfferListView}
                                exact
                                path={["/offers", "/offers/add"]}
                                roles={[roles.offerAdmin]}
                              />
                              <AuthorizedRoute
                                component={EditOfferView}
                                path="/offers/:identifier"
                                roles={[roles.offerAdmin]}
                              />
                              <AuthorizedRoute
                                component={ProgramListView}
                                exact
                                path={["/programs", "/programs/add"]}
                                roles={[roles.programAdmin]}
                              />
                              <AuthorizedRoute
                                component={EditProgramView}
                                path="/programs/:id"
                                roles={[roles.programAdmin]}
                              />
                              <AuthorizedRoute
                                component={RecurringListView}
                                exact
                                path={["/recurring", "/recurring/add"]}
                                roles={[roles.offerAdmin]}
                              />
                              <AuthorizedRoute
                                component={EditRecurringView}
                                path="/recurring_engagement/:id"
                                roles={[roles.offerAdmin]}
                              />
                              <AuthorizedRoute
                                component={EditUserView}
                                path="/users/:id"
                                roles={[roles.userManager]}
                              />
                              <AuthorizedRoute
                                component={UserListView}
                                path="/users"
                                roles={[roles.userManager]}
                              />
                              <AuthorizedRoute
                                component={DeepLinkListView}
                                exact
                                path={["/deep_links", "/deep_links/add"]}
                                roles={[roles.deepLinkAdmin]}
                              />
                              <AuthorizedRoute
                                component={EditDeepLinkView}
                                exact
                                path="/deep_links/:id"
                                roles={[roles.deepLinkAdmin]}
                              />
                              <AuthorizedRoute
                                component={CustomizationView}
                                exact
                                path={[
                                  "/customization/logos",
                                  "/customization/fonts",
                                  "/customization/colors",
                                  "/customization",
                                ]}
                                roles={[roles.superAdmin]}
                              />
                              <AuthorizedRoute
                                component={WorkspaceSettings}
                                exact
                                path={[
                                  "/workspace_settings",
                                  "/workspace_settings/features",
                                  "/workspace_settings/internationalization",
                                ]}
                                roles={[roles.superAdmin]}
                              />
                              <AuthorizedRoute
                                component={AppSettingsView}
                                exact
                                path="/app_settings"
                                roles={[roles.superAdmin]}
                              />
                            </Switch>
                          </Layout.Content>
                        </Layout>
                      </LocaleProvider>
                    </AuthenticatedRoute>
                  </Switch>
                </HelmetProvider>
              </SessionManager>
            </CognitoConfigManager>
          </ClientProvider>
        </QueryClientProvider>
      </NativeBaseProvider>
    </React.Suspense>
  );
};

export default App;
