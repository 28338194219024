import { Modal, notification, Select, Typography } from "antd";
import React from "react";

import Notifications from "../../assets/data/notifications";
import {
  useQueryClient,
  useUpdateWorkspaceSettings,
  useWorkspaceLocales,
  useWorkspaceSettings,
} from "../../client";

const WorkspaceDefaultLocaleSelect: React.FunctionComponent = () => {
  const queryClient = useQueryClient();
  const workspaceSettings = useWorkspaceSettings({
    select: (data) => data.data,
  });
  const workspaceLocales = useWorkspaceLocales({
    select: (data) =>
      data.data.filter(
        (workspaceLocale) => workspaceLocale.state !== "archived"
      ),
  });

  const {
    mutate: updateWorkspaceSettings,
    isLoading: isUpdateworkspaceSettingsLoading,
  } = useUpdateWorkspaceSettings({
    onSuccess: () => {
      queryClient.invalidateQueries(["workspace-settings"]);
      notification.success({
        message: "Success",
        description: Notifications.WorkspaceDefaultLocale.success,
      });
    },
    onError: (error) => {
      notification.error({
        message: "Error",
        description: `${Notifications.WorkspaceDefaultLocale.error} ${error?.errors?.[0]?.detail}`,
      });
    },
  });

  const handleDefaultLocaleSelect = React.useCallback(
    (workspaceLocaleId) => {
      if (workspaceLocales?.data) {
        const workspaceLocale = workspaceLocales.data.find(
          (workspaceLocale) => workspaceLocale.id === workspaceLocaleId
        );
        if (workspaceLocale) {
          Modal.confirm({
            title: "Change default language",
            content: (
              <Typography.Text>
                The default language participants see will be{" "}
                {workspaceLocale.locale.data.display_name}. Non-localized
                content will only be editable in{" "}
                {workspaceLocale.locale.data.display_name}.
              </Typography.Text>
            ),
            okText: "Yes. Continue",
            cancelText: "Cancel",
            icon: null,
            onOk: () => {
              updateWorkspaceSettings({
                default_workspace_locale_id: workspaceLocaleId,
              });
            },
          });
        }
      }
    },
    [updateWorkspaceSettings, workspaceLocales.data]
  );

  return (
    <Select
      loading={
        workspaceSettings.isFetching ||
        workspaceLocales.isFetching ||
        isUpdateworkspaceSettingsLoading
      }
      onSelect={handleDefaultLocaleSelect}
      style={{ width: "300px" }}
      value={workspaceSettings.data?.default_workspace_locale?.data.id}
    >
      {workspaceLocales?.data?.map((workspaceLocale) => (
        <Select.Option key={workspaceLocale.id} value={workspaceLocale.id}>
          {workspaceLocale.locale?.data.display_name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default WorkspaceDefaultLocaleSelect;
