import { Spin } from "antd";
import React from "react";

import {
  useExperimentByKey,
  useWorkspaceLocales,
  useWorkspaceSettings,
} from "../../client";
import { LocaleContext, LocaleProviderProps } from "./LocaleContext.types";

const Context = React.createContext<LocaleContext | undefined>(undefined);

const LocaleProvider: React.FunctionComponent<LocaleProviderProps> = ({
  children,
}) => {
  const internationalizationExperiment = useExperimentByKey({
    key: "internationalization_settings_enabled",
  });
  const savedLocale = localStorage.getItem("locale");
  const [defaultLocale, setDefaultLocale] = React.useState<string>("en-US");
  const [locale, setLocale] = React.useState<string>(savedLocale ?? "en-US");

  const workspaceSettings = useWorkspaceSettings({
    enabled: internationalizationExperiment?.experiment?.active ?? false,
  });
  const workspaceLocales = useWorkspaceLocales({
    enabled: workspaceSettings.isSuccess,
  });

  /**
   * Set the current default locale based on the workspace settings.
   * Additionally, if there is no saved locale, set the locale to the default.
   */
  const setWorkspaceDefaultLocale = React.useCallback(() => {
    if (workspaceSettings.data && workspaceLocales.data) {
      const defaultLocale = workspaceLocales.data.data.find(
        (workspaceLocale) =>
          workspaceLocale.id ===
          workspaceSettings.data.data.default_workspace_locale?.data?.id
      );
      if (defaultLocale) {
        setDefaultLocale(defaultLocale?.locale?.data?.identifier);
        if (!savedLocale) {
          setLocale(defaultLocale.locale?.data?.identifier);
        }
      }
    }
  }, [savedLocale, workspaceLocales.data, workspaceSettings.data]);

  const setLocaleLocal = React.useCallback((locale) => {
    localStorage.setItem("locale", locale);
    setLocale(locale);
  }, []);

  const resetLocale = React.useCallback(() => {
    localStorage.removeItem("locale");
    setWorkspaceDefaultLocale();
  }, [setWorkspaceDefaultLocale]);

  if (
    workspaceSettings.isLoading ||
    workspaceLocales.isLoading ||
    internationalizationExperiment.isLoading
  ) {
    return <Spin />;
  }

  return (
    <Context.Provider
      value={{
        defaultLocale,
        locale: internationalizationExperiment?.experiment?.active
          ? locale
          : "en-US",
        setLocale: setLocaleLocal,
        resetLocale,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useLocaleContext = (): LocaleContext => {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error("LocaleContext must be used within LocaleProvider");
  }

  return context;
};

const useLocale = (): string => {
  const context = useLocaleContext();
  return context.locale;
};

const useDefaultLocale = (): string => {
  const context = useLocaleContext();
  return context.defaultLocale;
};

const useIsDefaultLocale = (): boolean => {
  const context = useLocaleContext();
  return context.locale === context.defaultLocale;
};

export {
  LocaleProvider,
  useLocaleContext,
  useLocale,
  useDefaultLocale,
  useIsDefaultLocale,
};

export default Context;
